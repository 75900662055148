@mixin box-shadow {
    box-shadow: 0 0 20px rgba(27,55,100,.3);
}
@mixin box ($width, $height, $bg-color){
    width: $width;
    height: $height;
    background-color: $bg-color;
}
@mixin border ($brd-color) {
    border: 1px solid $brd-color;
}
@mixin transition {
    transition: all .3s ease-in-out;
}