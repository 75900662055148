@use "../components/base/main-base" as *;
@use "../components/utils/main-utils" as *;

footer {
    position: relative;
    background-color: $bg-color;
    box-shadow: 0 4px 23px 0 rgba(0, 0, 0, 0.25);
    .footer-wrapper {
        max-width: 1230px;
        margin: auto;
        .footer-top {
            .footer-logo {
                max-width: 103px;
                @include xsm {
                    max-width: 90px;
                }
            }
            .footer-social {
                gap: 16px;
                @include xsm {
                    gap: 12px;
                }
                a {
                    img {
                        width: 26px;
                        @include xsm {
                            width: 20px;
                        }
                    }
                }
            }
        }
        .footer-bottom {
            border-top: 1px solid #D9D9D9;
            .copyright-text {
                font-family: $primary-font;
                font-weight: 400;
                font-size: 14px;
                line-height: 1.5;
                color: $black;
                @include xsm {
                    font-size: 12px;
                }
                a {
                    @extend .copyright-text;
                    text-decoration: underline;
                    &:hover {
                        color: $dark-primary;
                    }
                }
            }
        }
    }
    .scroll-to-top {
        a {
            width: 52px;
            height: 52px;
            border-radius: 50%;
            background-color: $white;
            margin-top: -50%;
            box-shadow: 0 -2px 0 0 rgba(0, 0, 0, 0.25);
            @include xsm {
                width: 40px;
                height: 40px;
            }
            img {
                width: 22px;
                @include xsm {
                    width: 18px;
                }
            }
        }
    }
}