@use "../components/base/main-base" as *;
@use "../components/utils/main-utils" as *;

#contacts-section {
    padding-bottom: 195px;
    @include md {
        padding-bottom: 110px;
    }
    @include xsm {
        padding-bottom: 80px;
    }
    .contacts-wrapper {
        max-width: 1230px;
        margin: auto;
        background-color: $bg-color;
        border-radius: 40px;
        overflow: hidden;
        @include md {
            border-radius: 20px;
        }
        @include xsm {
            border-radius: 10px;
        }
        .contacts-contents {
            padding: 50px 60px 35px;
            @include md {
                padding: 40px;
            }
            @include xsm {
                padding: 30px 20px 40px;
            }
            h2 {
                font-family: $secondary-font;
                font-size: 48px;
                @include xsm {
                    font-size: 32px;
                }
            }
            .contacts-items {
                @include md {
                    display: flex;
                    justify-content: space-between;
                }
                @include sm {
                    flex-direction: column;
                    gap: 20px;
                }
                .contacts-details {
                    .single-contact {
                        margin-bottom: 8px;
                        @include xsm {
                            margin-bottom: 10px;
                        }
                        span {
                            height: 24px;
                            width: 24px;
                            margin-right: 10px;
                            @include xsm {
                                height: 20px;
                                width: 20px;
                                margin-right: 7px;
                            }
                        }
                        .contact-item {
                            font-family: $primary-font;
                            font-weight: 600;
                            font-size: 16px;
                            color: $black;
                            @include xsm {
                                font-size: 14px;
                            }
                            a {
                                @extend .contact-item;
                                &:hover{
                                    color: $dark-primary;
                                }
                            }
                        }
                    }
                }
                .opening-time {

                    h4 {
                        font-family: $open-sans;
                        font-weight: 600;
                        font-size: 16px;
                        color: $black;
                        margin-bottom: 10px;
                    }
                    .single-time-info {
                        margin-bottom: 8px;
                        p, span {
                            font-family: $open-sans;
                            font-weight: 400;
                            font-size: 16px;
                            color: $black;
                            width: 100px;
                            text-align: left;
                            @include xsm {
                                font-size: 14px;
                            }
                        }
                        span {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        .location-map {
            iframe {
                @include md {
                    height: 300px;
                }
                @include xsm {
                    height: 250px;
                }
            }
        }
    }
}