@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  background-color: #fff;
  color: #000;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  text-rendering: optimizeLegibility;
  line-height: unset;
  font-size: 1rem;
  overflow-x: hidden;
}

section, .section {
  position: relative;
}

a {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

a, button, .btn {
  outline: none;
  box-shadow: none;
  border: none;
  background: unset;
}
a:hover, a:focus, button:hover, button:focus, .btn:hover, .btn:focus {
  outline: none;
  box-shadow: none;
  border: none;
  background: unset;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

ul, ol {
  margin: 0;
  padding: 0;
}
ul li, ol li {
  list-style-type: none;
}

input, select, .form-select, textarea {
  outline: none;
  border: none;
  box-shadow: none;
}
input:focus, input:focus-visible, input:active, select:focus, select:focus-visible, select:active, .form-select:focus, .form-select:focus-visible, .form-select:active, textarea:focus, textarea:focus-visible, textarea:active {
  outline: none;
  border: none;
  box-shadow: none;
}

* + address, * + dl, * + fieldset, * + figure, * + ol, * + p, * + pre, * + ul {
  margin: 0;
  padding: 0;
}

.overflow-auto {
  overflow: auto;
}

.lg-backdrop {
  background: rgba(0, 0, 0, 0.65);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(4.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1 {
  color: #000;
  font-family: "Dream Avenue";
  font-size: 101px;
  line-height: normal;
}

header {
  background-color: #fff;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  transition: all 0.3s ease-in-out;
}
header.header-scrolled {
  position: fixed;
  height: auto;
}
header.header-scrolled .header-wrapper .header-nav ul li a {
  padding: 35px 44px;
}
@media only screen and (max-width: 1199px) {
  header.header-scrolled .header-wrapper .header-nav ul li a {
    padding: 30px 25px;
  }
}
header .header-wrapper .logo {
  width: 43px;
}
@media only screen and (max-width: 1199px) {
  header .header-wrapper .logo {
    width: 35px;
  }
}
@media only screen and (max-width: 991px) {
  header .header-wrapper .logo {
    width: 30px;
  }
}
@media only screen and (max-width: 575px) {
  header .header-wrapper .logo {
    width: 23px;
  }
}
header .header-wrapper .header-nav ul {
  padding: 0;
}
header .header-wrapper .header-nav ul li.current a {
  color: #DFB1B3;
}
header .header-wrapper .header-nav ul li.current a::after {
  width: 100%;
}
header .header-wrapper .header-nav ul li a {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #000;
  font-size: 20px;
  padding: 46px 44px 40px;
  transition: all 0.3s ease-in-out;
}
header .header-wrapper .header-nav ul li a::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 0;
  height: 9px;
  background-color: #DFB1B3;
  transition: all 0.3s ease-in-out;
}
header .header-wrapper .header-nav ul li a:hover::after {
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  header .header-wrapper .header-nav ul li a {
    padding: 35px 25px 35px;
  }
}
header .header-wrapper .mobile-menu .mobile-menu-btn {
  font-size: 22px;
  transition: all 0.3s ease-in-out;
}
header .header-wrapper .mobile-menu .mobile-menu-btn:hover {
  color: #DFB1B3;
}
@media only screen and (max-width: 575px) {
  header .header-wrapper .mobile-menu .mobile-menu-btn {
    font-size: 20px;
  }
}
header .header-wrapper .mobile-menu .offcanvas {
  background-color: #F7F2EC;
}
header .header-wrapper .mobile-menu .offcanvas .offcanvas-header {
  padding: 20px 16px;
}
header .header-wrapper .mobile-menu .offcanvas .offcanvas-header img {
  width: 30px;
}
@media only screen and (max-width: 575px) {
  header .header-wrapper .mobile-menu .offcanvas .offcanvas-header img {
    width: 23px;
  }
}
header .header-wrapper .mobile-menu .offcanvas .offcanvas-header .mobile-menu-close {
  font-size: 20px;
  padding: 8px 10px;
  border: 1px solid #000;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}
header .header-wrapper .mobile-menu .offcanvas .offcanvas-header .mobile-menu-close:hover {
  background-color: #000;
  color: #fff;
}
@media only screen and (max-width: 575px) {
  header .header-wrapper .mobile-menu .offcanvas .offcanvas-header .mobile-menu-close {
    font-size: 16px;
    padding: 6px 8px;
  }
}
header .header-wrapper .mobile-menu .offcanvas .offcanvas-body {
  padding: 0 16px;
}
header .header-wrapper .mobile-menu .offcanvas .offcanvas-body .offcanvas-menu ul li {
  border-bottom: 1px solid #000;
}
header .header-wrapper .mobile-menu .offcanvas .offcanvas-body .offcanvas-menu ul li:first-child {
  border-top: 1px solid #000;
}
header .header-wrapper .mobile-menu .offcanvas .offcanvas-body .offcanvas-menu ul li.current a {
  color: #DFB1B3;
}
header .header-wrapper .mobile-menu .offcanvas .offcanvas-body .offcanvas-menu ul li a {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #000;
  font-size: 16px;
  padding: 15px 0;
  display: block;
}
header .header-wrapper .mobile-menu .offcanvas .offcanvas-body .offcanvas-menu ul li a:hover {
  color: #DFB1B3;
}
@media only screen and (max-width: 420px) {
  header .header-wrapper .mobile-menu .offcanvas-start {
    width: 100%;
  }
}
@media only screen and (max-width: 991px) {
  header {
    padding: 10px 0;
  }
}

#hero-section {
  background: url("/dist/sanneshaarstudio/imgs/hero-bg.jpg") no-repeat center/cover;
  min-height: 784px;
}
@media only screen and (max-width: 1199px) {
  #hero-section {
    min-height: 650px;
  }
}
@media only screen and (max-width: 991px) {
  #hero-section {
    min-height: 550px;
  }
}
@media only screen and (max-width: 575px) {
  #hero-section {
    min-height: 400px;
  }
}
#hero-section::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.5) 75%, rgb(255, 255, 255) 100%);
}
#hero-section .hero-wrapper {
  z-index: 3;
}
@media only screen and (max-width: 1199px) {
  #hero-section .hero-wrapper h1 {
    font-size: 80px;
  }
}
@media only screen and (max-width: 991px) {
  #hero-section .hero-wrapper h1 {
    font-size: 70px;
  }
}
@media only screen and (max-width: 575px) {
  #hero-section .hero-wrapper h1 {
    font-size: 50px;
    line-height: 1;
  }
}

#about-section {
  padding-bottom: 180px;
}
@media only screen and (max-width: 1199px) {
  #about-section {
    padding-bottom: 120px;
  }
}
@media only screen and (max-width: 991px) {
  #about-section {
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 575px) {
  #about-section {
    padding-bottom: 70px;
  }
}
#about-section .about-wrapper {
  max-width: 1230px;
  margin: -50px auto 0;
  background-color: #F7F2EC;
  border-radius: 40px;
  overflow: hidden;
}
@media only screen and (max-width: 991px) {
  #about-section .about-wrapper {
    border-radius: 20px;
  }
}
@media only screen and (max-width: 575px) {
  #about-section .about-wrapper {
    border-radius: 10px;
  }
}
@media only screen and (max-width: 991px) {
  #about-section .about-wrapper .about-thumb img {
    width: 100% !important;
    height: 700px !important;
  }
}
@media only screen and (max-width: 767px) {
  #about-section .about-wrapper .about-thumb img {
    height: 500px !important;
  }
}
@media only screen and (max-width: 420px) {
  #about-section .about-wrapper .about-thumb img {
    height: 350px !important;
  }
}
#about-section .about-wrapper .about-content {
  padding: 70px 54px 50px;
}
@media only screen and (max-width: 1199px) {
  #about-section .about-wrapper .about-content {
    padding: 40px 25px;
  }
}
@media only screen and (max-width: 991px) {
  #about-section .about-wrapper .about-content {
    padding: 40px;
  }
}
@media only screen and (max-width: 575px) {
  #about-section .about-wrapper .about-content {
    padding: 30px 20px;
  }
}
#about-section .about-wrapper .about-content h2 {
  font-size: 35px;
  font-family: "Dream Avenue";
  color: #000;
  letter-spacing: 2px;
}
@media only screen and (max-width: 1199px) {
  #about-section .about-wrapper .about-content h2 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 575px) {
  #about-section .about-wrapper .about-content h2 {
    font-size: 24px;
  }
}
#about-section .about-wrapper .about-content p {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  color: #000;
}
@media only screen and (max-width: 575px) {
  #about-section .about-wrapper .about-content p {
    font-size: 16px;
  }
}
#about-section .about-wrapper .about-content p a {
  color: #000;
}

#gallery-section {
  padding-bottom: 180px;
}
@media only screen and (max-width: 1199px) {
  #gallery-section {
    padding-bottom: 120px;
  }
}
@media only screen and (max-width: 991px) {
  #gallery-section {
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 575px) {
  #gallery-section {
    padding-bottom: 60px;
  }
}
#gallery-section .gallery-wrapper {
  max-width: 1230px;
  margin: auto;
}
@media only screen and (max-width: 1199px) {
  #gallery-section .gallery-wrapper #gallery-popup {
    margin-right: -3%;
  }
}
@media only screen and (max-width: 575px) {
  #gallery-section .gallery-wrapper #gallery-popup {
    margin-right: 0;
  }
}
#gallery-section .gallery-wrapper #gallery-popup .single-img {
  width: 24%;
  margin-bottom: 20px;
  border-radius: 40px;
  overflow: hidden;
}
@media only screen and (max-width: 1199px) {
  #gallery-section .gallery-wrapper #gallery-popup .single-img {
    width: 31%;
    margin-right: 2%;
  }
}
@media only screen and (max-width: 991px) {
  #gallery-section .gallery-wrapper #gallery-popup .single-img {
    width: 47%;
    margin-right: 3%;
    height: 300px;
    border-radius: 20px;
  }
}
@media only screen and (max-width: 575px) {
  #gallery-section .gallery-wrapper #gallery-popup .single-img {
    width: 100%;
    height: 274px;
    margin-right: 0;
    border-radius: 10px;
  }
}
#gallery-section .gallery-wrapper #gallery-popup .single-img img {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
}
#gallery-section .gallery-wrapper #gallery-popup .single-img img:hover {
  transform: scale(1.03);
}

#prices-section {
  padding-bottom: 135px;
}
@media only screen and (max-width: 991px) {
  #prices-section {
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 575px) {
  #prices-section {
    padding-bottom: 70px;
  }
}
#prices-section .prices-wrapper {
  max-width: 1230px;
  margin: auto;
  padding: 50px 60px 25px;
  background-color: #F7F2EC;
  border-radius: 40px;
}
@media only screen and (max-width: 991px) {
  #prices-section .prices-wrapper {
    border-radius: 20px;
  }
}
@media only screen and (max-width: 575px) {
  #prices-section .prices-wrapper {
    border-radius: 0;
    padding: 40px 20px;
  }
}
#prices-section .prices-wrapper h2 {
  font-family: "Dream Avenue";
  font-size: 70px;
}
@media only screen and (max-width: 991px) {
  #prices-section .prices-wrapper h2 {
    font-size: 60px;
  }
}
@media only screen and (max-width: 575px) {
  #prices-section .prices-wrapper h2 {
    font-size: 48px;
  }
}
#prices-section .prices-wrapper .prices-tab nav .nav {
  justify-content: center;
  border: unset;
  gap: 65px;
}
@media only screen and (max-width: 991px) {
  #prices-section .prices-wrapper .prices-tab nav .nav {
    gap: 30px;
  }
}
#prices-section .prices-wrapper .prices-tab nav .nav button {
  border: unset;
  padding: 0;
  background-color: transparent;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: #000;
  position: relative;
}
#prices-section .prices-wrapper .prices-tab nav .nav button::after, #prices-section .prices-wrapper .prices-tab nav .nav button.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #000;
  transition: all 0.3s ease-in-out;
}
#prices-section .prices-wrapper .prices-tab nav .nav button:hover::after, #prices-section .prices-wrapper .prices-tab nav .nav button.active::after {
  width: 100%;
}
#prices-section .prices-wrapper .prices-tab .tab-content {
  margin-top: 45px;
  padding: 25px 45px;
  background-color: #fff;
  box-shadow: 0 7px 21px 0 rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 575px) {
  #prices-section .prices-wrapper .prices-tab .tab-content {
    padding: 5px 20px;
  }
}
#prices-section .prices-wrapper .prices-tab .tab-content .tab-content-wrapper {
  padding: 20px 0;
  border-bottom: 1px dashed #B7B7B7;
}
@media only screen and (max-width: 575px) {
  #prices-section .prices-wrapper .prices-tab .tab-content .tab-content-wrapper {
    padding: 15px 0;
  }
}
#prices-section .prices-wrapper .prices-tab .tab-content .tab-content-wrapper h4, #prices-section .prices-wrapper .prices-tab .tab-content .tab-content-wrapper span {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 1.85;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
}
@media only screen and (max-width: 575px) {
  #prices-section .prices-wrapper .prices-tab .tab-content .tab-content-wrapper h4, #prices-section .prices-wrapper .prices-tab .tab-content .tab-content-wrapper span {
    font-size: 12px;
  }
}
#prices-section .prices-wrapper .prices-tab .tab-content .tab-content-wrapper:last-child {
  border-bottom: unset;
}
#prices-section .prices-wrapper .price-logo {
  max-width: 315px;
}
@media only screen and (max-width: 1199px) {
  #prices-section .prices-wrapper .price-logo {
    max-width: 250px;
  }
}
@media only screen and (max-width: 991px) {
  #prices-section .prices-wrapper .price-logo {
    max-width: 200px;
  }
}
@media only screen and (max-width: 575px) {
  #prices-section .prices-wrapper .price-logo {
    max-width: 150px;
  }
}

#contacts-section {
  padding-bottom: 195px;
}
@media only screen and (max-width: 991px) {
  #contacts-section {
    padding-bottom: 110px;
  }
}
@media only screen and (max-width: 575px) {
  #contacts-section {
    padding-bottom: 80px;
  }
}
#contacts-section .contacts-wrapper {
  max-width: 1230px;
  margin: auto;
  background-color: #F7F2EC;
  border-radius: 40px;
  overflow: hidden;
}
@media only screen and (max-width: 991px) {
  #contacts-section .contacts-wrapper {
    border-radius: 20px;
  }
}
@media only screen and (max-width: 575px) {
  #contacts-section .contacts-wrapper {
    border-radius: 10px;
  }
}
#contacts-section .contacts-wrapper .contacts-contents {
  padding: 50px 60px 35px;
}
@media only screen and (max-width: 991px) {
  #contacts-section .contacts-wrapper .contacts-contents {
    padding: 40px;
  }
}
@media only screen and (max-width: 575px) {
  #contacts-section .contacts-wrapper .contacts-contents {
    padding: 30px 20px 40px;
  }
}
#contacts-section .contacts-wrapper .contacts-contents h2 {
  font-family: "Dream Avenue";
  font-size: 48px;
}
@media only screen and (max-width: 575px) {
  #contacts-section .contacts-wrapper .contacts-contents h2 {
    font-size: 32px;
  }
}
@media only screen and (max-width: 991px) {
  #contacts-section .contacts-wrapper .contacts-contents .contacts-items {
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 767px) {
  #contacts-section .contacts-wrapper .contacts-contents .contacts-items {
    flex-direction: column;
    gap: 20px;
  }
}
#contacts-section .contacts-wrapper .contacts-contents .contacts-items .contacts-details .single-contact {
  margin-bottom: 8px;
}
@media only screen and (max-width: 575px) {
  #contacts-section .contacts-wrapper .contacts-contents .contacts-items .contacts-details .single-contact {
    margin-bottom: 10px;
  }
}
#contacts-section .contacts-wrapper .contacts-contents .contacts-items .contacts-details .single-contact span {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}
@media only screen and (max-width: 575px) {
  #contacts-section .contacts-wrapper .contacts-contents .contacts-items .contacts-details .single-contact span {
    height: 20px;
    width: 20px;
    margin-right: 7px;
  }
}
#contacts-section .contacts-wrapper .contacts-contents .contacts-items .contacts-details .single-contact .contact-item, #contacts-section .contacts-wrapper .contacts-contents .contacts-items .contacts-details .single-contact .contact-item a {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #000;
}
@media only screen and (max-width: 575px) {
  #contacts-section .contacts-wrapper .contacts-contents .contacts-items .contacts-details .single-contact .contact-item, #contacts-section .contacts-wrapper .contacts-contents .contacts-items .contacts-details .single-contact .contact-item a {
    font-size: 14px;
  }
}
#contacts-section .contacts-wrapper .contacts-contents .contacts-items .contacts-details .single-contact .contact-item a:hover {
  color: #DFB1B3;
}
#contacts-section .contacts-wrapper .contacts-contents .contacts-items .opening-time h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #000;
  margin-bottom: 10px;
}
#contacts-section .contacts-wrapper .contacts-contents .contacts-items .opening-time .single-time-info {
  margin-bottom: 8px;
}
#contacts-section .contacts-wrapper .contacts-contents .contacts-items .opening-time .single-time-info p, #contacts-section .contacts-wrapper .contacts-contents .contacts-items .opening-time .single-time-info span {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000;
  width: 100px;
  text-align: left;
}
@media only screen and (max-width: 575px) {
  #contacts-section .contacts-wrapper .contacts-contents .contacts-items .opening-time .single-time-info p, #contacts-section .contacts-wrapper .contacts-contents .contacts-items .opening-time .single-time-info span {
    font-size: 14px;
  }
}
#contacts-section .contacts-wrapper .contacts-contents .contacts-items .opening-time .single-time-info span {
  font-weight: 600;
}
@media only screen and (max-width: 991px) {
  #contacts-section .contacts-wrapper .location-map iframe {
    height: 300px;
  }
}
@media only screen and (max-width: 575px) {
  #contacts-section .contacts-wrapper .location-map iframe {
    height: 250px;
  }
}

footer {
  position: relative;
  background-color: #F7F2EC;
  box-shadow: 0 4px 23px 0 rgba(0, 0, 0, 0.25);
}
footer .footer-wrapper {
  max-width: 1230px;
  margin: auto;
}
footer .footer-wrapper .footer-top .footer-logo {
  max-width: 103px;
}
@media only screen and (max-width: 575px) {
  footer .footer-wrapper .footer-top .footer-logo {
    max-width: 90px;
  }
}
footer .footer-wrapper .footer-top .footer-social {
  gap: 16px;
}
@media only screen and (max-width: 575px) {
  footer .footer-wrapper .footer-top .footer-social {
    gap: 12px;
  }
}
footer .footer-wrapper .footer-top .footer-social a img {
  width: 26px;
}
@media only screen and (max-width: 575px) {
  footer .footer-wrapper .footer-top .footer-social a img {
    width: 20px;
  }
}
footer .footer-wrapper .footer-bottom {
  border-top: 1px solid #D9D9D9;
}
footer .footer-wrapper .footer-bottom .copyright-text, footer .footer-wrapper .footer-bottom .copyright-text a {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: #000;
}
@media only screen and (max-width: 575px) {
  footer .footer-wrapper .footer-bottom .copyright-text, footer .footer-wrapper .footer-bottom .copyright-text a {
    font-size: 12px;
  }
}
footer .footer-wrapper .footer-bottom .copyright-text a {
  text-decoration: underline;
}
footer .footer-wrapper .footer-bottom .copyright-text a:hover {
  color: #DFB1B3;
}
footer .scroll-to-top a {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: #fff;
  margin-top: -50%;
  box-shadow: 0 -2px 0 0 rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 575px) {
  footer .scroll-to-top a {
    width: 40px;
    height: 40px;
  }
}
footer .scroll-to-top a img {
  width: 22px;
}
@media only screen and (max-width: 575px) {
  footer .scroll-to-top a img {
    width: 18px;
  }
}