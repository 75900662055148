@use "../components/base/main-base" as *;
@use "../components/utils/main-utils" as *;

#hero-section {
    background: url("/dist/sanneshaarstudio/imgs/hero-bg.jpg") no-repeat center / cover;
    min-height: 784px;
    @include lg {
        min-height: 650px;
    }
    @include md {
        min-height: 550px;
    }
    @include xsm {
        min-height: 400px;
    }
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(255,255,255,0.1) 50%, rgba(255,255,255,.5) 75%, rgba(255,255,255,1) 100%);
    }
    .hero-wrapper {
        z-index: 3;
        h1 {
            @include lg {
                font-size: 80px;
            }
            @include md {
                font-size: 70px;
            }
            @include xsm {
                font-size: 50px;
                line-height: 1;
            }
        }
    }
}