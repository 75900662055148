@use "../components/base/main-base" as *;
@use "../components/utils/main-utils" as *;

#gallery-section {
    padding-bottom: 180px;
    @include lg {
        padding-bottom: 120px;
    }
    @include md {
        padding-bottom: 100px;
    }
    @include xsm {
        padding-bottom: 60px;
    }
    .gallery-wrapper {
        max-width: 1230px;
        margin: auto;
        #gallery-popup {
            @include lg {
                margin-right: -3%;
            }
            @include xsm {
                margin-right: 0;
            }
            .single-img {
                width: 24%;
                margin-bottom: 20px;
                border-radius: 40px;
                overflow: hidden;
                @include lg {
                    width: 31%;
                    margin-right: 2%;
                }
                @include md {
                    width: 47%;
                    margin-right: 3%;
                    height: 300px;
                    border-radius: 20px;
                }
                @include xsm {
                    width: 100%;
                    height: 274px;
                    margin-right: 0;
                    border-radius: 10px;
                }
                img {
                    width: 100%;
                    height: 100%;
                    @include transition;
                    &:hover {
                        transform: scale(1.03);
                    }
                }
            }
        }
    }
}