@use "../components/base/main-base" as *;
@use "../components/utils/main-utils" as *;

header {
    background-color: $white;
    box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9;
    @include transition;
    &.header-scrolled {
        position: fixed;
        height: auto;
        .header-wrapper {
            .header-nav {
                ul {
                    li {
                        a {
                            padding: 35px 44px;
                            @include lg {
                                padding: 30px 25px;
                            }
                        }
                    }
                }
            }
        }
    }
    .header-wrapper {
        .logo {
            width: 43px;
            @include lg {
                width: 35px;
            }
            @include md {
                width: 30px;
            }
            @include xsm {
                width: 23px;
            }
        }
        .header-nav {
            ul {
                padding: 0;
                li {
                    // &:first-of-type {
                    //     a {
                    //         &::after {
                    //             border-radius: 5px 0 0 5px;
                    //         }
                    //     }
                    // }
                    // &:last-of-type {
                    //     a {
                    //         &::after {
                    //             border-radius: 0 5px 5px 0;
                    //         }
                    //     }
                    // }
                    &.current{
                        & a{
                            color: $dark-primary;
                            &::after{
                                width: 100%;
                            }
                        }
                    }
                    a {
                        font-family: $primary-font;
                        font-weight: 600;
                        color: $black;
                        font-size: 20px;
                        padding: 46px 44px 40px;
                        @include transition;
                        &::after {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            content: "";
                            width: 0;
                            height: 9px;
                            // border-radius: 5px;
                            background-color: $dark-primary;
                            @include transition;
                        }
                        &:hover::after {
                            width: 100%;
                        }
                        @include lg {
                            padding: 35px 25px 35px;
                        }
                    }
                }
            }
        }
        .mobile-menu {
            .mobile-menu-btn {
                font-size: 22px;
                @include transition;
                &:hover {
                    color: $dark-primary;
                }
                @include xsm {
                    font-size: 20px;
                }
            }
            .offcanvas {
                background-color: $bg-color;
                .offcanvas-header {
                    padding: 20px 16px;
                    img {
                        width: 30px;
                        @include xsm {
                            width: 23px;
                        }
                    }
                    .mobile-menu-close {
                        font-size: 20px;
                        padding: 8px 10px;
                        border: 1px solid #000;
                        border-radius: 3px;
                        @include transition;
                        &:hover {
                            background-color: $black;
                            color: #fff;
                        }
                        @include xsm {
                            font-size: 16px;
                            padding: 6px 8px;
                        }
                    }
                }
                .offcanvas-body {
                    padding: 0 16px;
                    .offcanvas-menu {
                        ul {
                            li {
                                border-bottom: 1px solid $black;
                                &:first-child {
                                    border-top: 1px solid $black;
                                }
                                &.current{
                                    & a{
                                        color: $dark-primary;
                                    
                                    }
                                }
                                a {
                                    font-family: $primary-font;
                                    font-weight: 600;
                                    color: $black;
                                    font-size: 16px;
                                    padding: 15px 0;
                                    display: block;
                                    &:hover {
                                        color: $dark-primary;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .offcanvas-start {
                @include mb-device {
                    width: 100%;
                }
            }
        }
    }
    @include md {
        padding: 10px 0;
    }
}