@use "../components/base/main-base" as *;
@use "../components/utils/main-utils" as *;

#about-section {
    padding-bottom: 180px;
    @include lg {
        padding-bottom: 120px;
    }
    @include md {
        padding-bottom: 100px;
    }
    @include xsm {
        padding-bottom: 70px;
    }
    .about-wrapper {
        max-width: 1230px;
        margin: -50px auto 0;
        background-color: $bg-color;
        border-radius: 40px;
        overflow: hidden;
        @include md {
            border-radius: 20px;
        }
        @include xsm {
            border-radius: 10px;
        }
        .about-thumb {
            img {
                @include md {
                    width: 100% !important;
                    height: 700px !important;
                }
                @include sm {
                    height: 500px !important;
                }
                @include mb-device {
                    height: 350px !important;
                }
            }
        }
        .about-content {
            padding: 70px 54px 50px;
            @include lg {
                padding: 40px 25px;
            }
            @include md {
                padding: 40px;
            }
            @include xsm {
                padding: 30px 20px;
            }
            h2 {
                font-size: 35px;
                font-family: $secondary-font;
                color: $black;
                letter-spacing: 2px;
                @include lg {
                    font-size: 30px;
                }
                @include xsm {
                    font-size: 24px;
                }
            }
            p {
                font-family: $primary-font;
                font-size: 18px;
                line-height: 1.5;
                color: $black;
                @include xsm {
                    font-size: 16px;
                }
                a {
                    color: $black;
                }
            }
        }
    }
}