// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@import "../utils/main-utils";


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
    font-size: 100%;
    scroll-behavior: smooth;
}
body {
    background-color: $white;
    color: $black;
    font-size: 1rem;
    font-family: $primary-font;
    text-rendering: optimizeLegibility;
    line-height: unset;
    font-size: 1rem;
    overflow-x:hidden;
}
section, .section {
    position: relative;
}
a {
    text-decoration: none;
    @include transition;
}
a, button, .btn {
	outline: none;
	box-shadow: none;
	border: none;
	background: unset;
    &:hover, &:focus {
        outline: none;
        box-shadow: none;
        border: none;
        background: unset;
    }
}
img {
    height: auto;
    max-width: 100%;
    object-fit: cover;
}
ul, ol {
    margin: 0;
    padding: 0;
    li {
        list-style-type: none;
    }
}
input, select, .form-select, textarea {
    outline: none;
    border: none;
    box-shadow: none;
    &:focus, &:focus-visible, &:active {
        outline: none;
        border: none;
        box-shadow: none;
    }
}
* + address, * + dl, * + fieldset, * + figure, * + ol, * + p, * + pre, * + ul {
	margin: 0;
	padding: 0;
}
.overflow-auto {
    overflow: auto;
}
.lg-backdrop {
    background: rgba( 0, 0, 0, 0.65 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4.5px );
    -webkit-backdrop-filter: blur( 4.5px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}