@use "../utils/main-utils" as *;

p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}
h1 {
    color: $black;
    font-family: $secondary-font;
    font-size: 101px;
    line-height: normal;
}
