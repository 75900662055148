@use "../components/base/main-base" as *;
@use "../components/utils/main-utils" as *;

#prices-section {
    padding-bottom: 135px;
    @include md {
        padding-bottom: 100px;
    }
    @include xsm {
        padding-bottom: 70px;
    }
    .prices-wrapper {
        max-width: 1230px;
        margin: auto;
        padding: 50px 60px 25px;
        background-color: $bg-color;
        border-radius: 40px;
        @include md {
            border-radius: 20px;
        }
        @include xsm {
            border-radius: 0;
            padding: 40px 20px;
        }
        h2 {
            font-family: $secondary-font;
            font-size: 70px;
            @include md {
                font-size: 60px;
            }
            @include xsm {
                font-size: 48px;
            }
        }
        .prices-tab {
            nav {
                .nav {
                    justify-content: center;
                    border: unset;
                    gap: 65px;
                    @include md {
                        gap: 30px;
                    }
                    button {
                        border: unset;
                        padding: 0;
                        background-color: transparent;
                        font-family: $primary-font;
                        font-weight: 700;
                        font-size: 14px;
                        text-transform: uppercase;
                        color: $black;
                        position: relative;
                        &::after, &.active::after {
                            content: "";
                            position: absolute;
                            bottom: -4px;
                            left: 0;
                            width: 0;
                            height: 2px;
                            background-color: $black;
                            @include transition;
                        }
                        &:hover::after, &.active::after {
                            width: 100%;
                        }
                    }
                }
            }
            .tab-content {
                margin-top: 45px;
                padding: 25px 45px;
                background-color: $white;
                box-shadow: 0 7px 21px 0 rgba(0, 0, 0, 0.1);
                @include xsm {
                    padding: 5px 20px;
                }
                .tab-content-wrapper {
                    padding: 20px 0;
                    border-bottom: 1px dashed #B7B7B7;
                    @include xsm {
                        padding: 15px 0;
                    }
                    h4, span {
                        font-family: $primary-font;
                        font-size: 14px;
                        line-height: 1.85;
                        font-weight: 700;
                        color: $black;
                        text-transform: uppercase;
                        @include xsm {
                            font-size: 12px;
                        }
                    }
                    &:last-child {
                        border-bottom: unset;
                    }
                }
            }
        }
        .price-logo {
            max-width: 315px;
            @include lg {
                max-width: 250px;
            }
            @include md {
                max-width: 200px;
            }
            @include xsm {
                max-width: 150px;
            }
        }
    }
}