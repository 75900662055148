@mixin fhd {
    @media only screen and (min-width:1601px){
        @content;
    }
}
@mixin xxl {
    @media only screen and (max-width:1600px){
        @content;
    }
}
@mixin xl {
    @media only screen and (max-width:1399px){
        @content;
    }
}
@mixin lg {
    @media only screen and (max-width:1199px){
        @content;
    }
}
@mixin md {
    @media only screen and (max-width:991px){
        @content;
    }
}
@mixin sm {
    @media only screen and (max-width:767px){
        @content;
    }
}

@mixin xsm {
    @media only screen and (max-width:575px){
        @content;
    }
}

@mixin mb-device {
    @media only screen and (max-width:420px){
        @content;
    }
}
