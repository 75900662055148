// Fonts
$primary-font: "Montserrat", sans-serif;
$secondary-font: 'Dream Avenue';
$open-sans: "Open Sans", sans-serif;

// Color Palette
$white: #fff;
$black: #000;
$primary: #F7F2EC;
$dark-primary: #DFB1B3;
$bg-color: #F7F2EC;
